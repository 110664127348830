import React, { Component } from "react"
import { Link } from "gatsby"
import SEO from "../components/seo"
import { Logo, LogoDesktop, People, Quote } from "../images"
import BackgroundImage from "../components/BackgroundImage"

class Home extends Component {
  render() {
    return (
      <BackgroundImage>
        <SEO title="Home" />
        <section className="home">
          <div className="home_top">
            <div className="logo--mobile">
              <img src={Logo} alt="logo" />
            </div>
            <h1 className="heading--l button-text">
              Have your fortune told by Madame Arcati
            </h1>
            <Link className="btn yellow mobile" to={"/reading"}>
              <span>begin reading</span>
            </Link>
            <img
              className="people"
              src={People}
              alt="Blithe Spirit movie poster"
            />
            <div className="home_footer--mobile">
              <Link className="btn " to={"/animations"}>
                <span>View Tarot Cards</span>
              </Link>

              <img
                src={Quote}
                alt="A coming masterclass from Judi Dench... Wickedly enjoyable"
              />
            </div>
            <div className="desktop">
              <div className="wrapper">
                <div className="row">
                  <div className="col col-4">
                    <img src={LogoDesktop} alt="Blithe Spirit movie heading" />
                  </div>
                  <div className="col col-4 col-empty"></div>
                  <div className="col col-4">
                    <div className="col-buttons">
                      <h3 className="heading--l">
                        Have your fortune told by Madame Arcati
                      </h3>
                      <Link className="btn yellow" to={"/reading"}>
                        <span>begin reading</span>
                      </Link>
                      <Link className="btn " to={"/animations"}>
                        <span>View Tarot Cards</span>
                      </Link>
                    </div>
                    <img className="quote" src={Quote} alt="quote" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="smoke_slider">
            <div className="smoke"></div>
          </div>
          <div className="smoke_slider smoke_slider--2">
            <div className="smoke"></div>
          </div>
          <p className="studiomade_link">
            A <a href="https://studiomade.co/">Studiomade</a> concept
          </p>
        </section>
      </BackgroundImage>
    )
  }
}

export default Home
